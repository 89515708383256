import React, { Component } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


class Legal extends Component {

  state = {
    persentation: 'Todos los derechos reservados Alan Emir Araujo Pino. ',
    servicios: 'Los programas o servicios encontrados en este sitio, no piden datos sensibles. Todos los datos recopilados son transformados a entidades numéricas de las cuales es imposible extraer información sensible, es decir toda información sensible es destruida. La seguridad de los datos recopilados se trata con las tecnologías más actuales con el fin de tener asegurar que nadie más que los desarrolladores tengan acceso, aun así este sitio tiene fines experimentales y en ningún momento se garantiza la seguridad de datos sensibles. En caso de requerir que tus datos sean borrados de estos sistemas, es necesario ponerse en contacto con el desarrollador de esta página.',
    contenido: 'Los programas, imágenes, servicios o cualquier contenido de este sitio estan desplegados con fines únicamente experimentales y de promoción. En ningún momento se genera ninguna relación de ningún tipo, ni garantía de funcionamiento ni de resultados. Para un desarrollo profesional de cualquier proyecto, contacte a Alan Emir Araujo Pino.'
  }

  plainText = (s) => {
    return (
      <p>
        <Typography align='justify' variant="body1" sx={{ flexGrow: 1 }}>
          {s} </Typography>
      </p>
    )
  }

  render() {
    return (
      <div>
        <p></p>

        <Typography align='center' variant="h4" component="div" sx={{ flexGrow: 1 }}>
          Informacion Legal </Typography>
        {this.plainText(this.state.persentation)}

        <Typography align='center' variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Sobre el contenido de este sitio </Typography>
        {this.plainText(this.state.contenido)}

        <Typography align='center' variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Sobre los servicios y uso de datos de este sitio </Typography>
        {this.plainText(this.state.servicios)}

        {this.plainText('**Esta pagina fue creada con React y MUI.**')}

        <a href="https://www.flaticon.es/iconos-gratis/letra-e" title="letra e iconos">Letra e iconos creados por Md Tanvirul Haque - Flaticon</a>
        
        </div>
    );
  }

}

export default Legal;