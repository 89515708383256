import * as React from 'react';
import ReactDOM from 'react-dom';
import { Component } from "react";

import EmirAraujo from "./components/EmirAraujo";
import textInterface from "./components/TextInterface";
import FootNote from "./components/FootNote"
import TopAppBar from  "./components/TopAppBar"
import TextInterface from './components/TextInterface';
import Legal from './components/Legal';
import Services from './components/Services';
import Gallery from './components/Gallery'
import { useTranslation } from 'react-i18next'
import { withNamespaces } from 'react-i18next';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: -1,
      idiom:1
    };
    this.setPage = this.setPage.bind(this);    
    }

    setPage(val){
      // do not forget to bind getData in constructor
      //this.setState( {"handlePage" : val})
      //this.state.page = val;
      this.setState({page:val});
      //console.log("get",val);
    }
  componentDidMount(){
    //console.log(this.state.page);
  }

  render (){
    return (
    <div className="App">
      <header >
      <TopAppBar sendData={this.setPage} />        
      </header> 
      <body>
      
        {this.state.page === -1 ? <EmirAraujo/> :null }
        {this.state.page === 6 ? <Services/> :null }
        {this.state.page === 4 ? <Legal/> :null }
        {this.state.page === 2 ? <Gallery/> :null }
        <FootNote sendData={this.setPage} />
        </body>

    </div>
    );
    }
}



export default App;
