import React, { useState } from 'react';
import { Component } from "react";
import axios from "axios";

class FileUpload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: 'Response',
    };
  }
  
  handleFileUpload = (event) => {
    // get the selected file from the input
    const file = event.target.files[0];
    // create a new FormData object and append the file to it
    const formData = new FormData();
    formData.append("file", file);
    // make a POST request to the File Upload API with the FormData object and Rapid API headers
    axios
      .post("https://www.emiraraujo.dev/app/whisper/base", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-rapidapi-host": "file-upload8.p.rapidapi.com",
          "x-rapidapi-key": "your-rapidapi-key-here",
        },
      })
      .then((response) => {
		// handle the response
        console.log(response);
        console.log(response.data);
        this.setState({data:response.data.result})
      })
      .catch((error) => {
        // handle errors
        console.log(error);
      });
  };
  // render a simple input element with an onChange event listener that calls the handleFileUpload function
  render (){
    return (
    <div>
      <input type="file" onChange={this.handleFileUpload} />
      <p></p>
        {this.state.data}
    </div>);
  }
};

export default FileUpload;