import * as React from 'react';
import { Component } from "react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageSelector from '../components/LanguageSelector';
import { withTranslation } from 'react-i18next';

class TopAppBar extends Component {
  constructor(props) {
    super(props);
    }

  render(){
    const { t,sendData } = this.props
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" >
          <Toolbar>
          

            <Button color="inherit" onClick={()=>{sendData(-1);}} >
            {/*speed up google searches */}
            <Typography variant="h1"  sx={{  fontSize:20 }}>
              Alan Emir Araujo
            </Typography>
            </Button>

            <Box sx={{ mx:1 }}>
              {/*<Button color="inherit" onClick={()=>{this.props.sendData(6);}}>
                {t('Servicios')}
              </Button>*/}
           
              <Button color="inherit" onClick={()=>{sendData(2);}}>
              {t('Gallery')}
              </Button>

              <LanguageSelector />
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
}

export default withTranslation()(TopAppBar);