import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import myData from './data.json';


class EmirAraujo extends Component {
  state = {

  }

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    //myData = jsonDict["EmirAraujo"]
    //this.setState({})
  }

  plainText = (s) => {
    return (
      <p>
        <Typography align='justify' variant="body1" sx={{ flexGrow: 1 }}>
          {s} </Typography>
      </p>
    )
  }

  render() {
    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    }));

    return (

      <div>

        <Typography align='center' variant='h1' component="div" sx={{ flexGrow: 1, fontSize: 30 }}>
          Alan Emir Araujo Pino
        </Typography>
        <Typography align='center' variant='body' component="div" sx={{ flexGrow: 1 }}>
          <a href="https://scholar.google.com/citations?user=C3vQsNEAAAAJ&hl=en">
            Google Schoolar
          </a>
        </Typography>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}>
          <Item>
            {this.plainText(myData["EmirAraujo"]["presentation"])}
          </Item>
          <Item>
            {this.plainText(myData["EmirAraujo"]["introLic"])}
            <a href="http://132.248.9.195/ptd2017/septiembre/0765041/Index.html">
              {myData["EmirAraujo"]["TesisLic"]}
            </a>
          </Item>
          <Item>
            {this.plainText(myData["EmirAraujo"]["introMaster"])}
            <a href="http://132.248.9.195/ptd2021/enero/0806806/Index.html">
              {myData["EmirAraujo"]["TesisMaster"]} </a>
            <p>

            </p>
            <a href="http://ceur-ws.org/Vol-2696/paper_222.pdf">
              {myData["EmirAraujo"]["Pan2020"]} </a>
          </Item>
        </Stack>

      </div>
    );
  }

}

export default EmirAraujo;