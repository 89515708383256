import * as React from 'react';
import { Component } from "react";

import Button from '@mui/material/Button';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GitHubIcon from '@mui/icons-material/GitHub';
import FeedIcon from '@mui/icons-material/Feed';
import CodeIcon from '@mui/icons-material/Code';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import CopyrightIcon from '@mui/icons-material/Copyright';
import PolicyIcon from '@mui/icons-material/Policy';
import Typography from '@mui/material/Typography';
import { height, maxHeight, minHeight, sizeHeight } from '@mui/system';
import Box from '@mui/material/Box';


class FootNote extends Component {
//export default function FootNote() {
    //const [value, setValue] = React.useState(0);
    constructor(props) {
        super(props);
        const ref = React.useRef<HTMLDivElement>(null);
        }


    render () {
        return (
            <Box sx={{ pb: 7 }} ref={this.ref}>
            <CssBaseline />
            
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: 1.0  }} elevation={0}>
            
            
            <Typography align='center' variant="body2" component="div" sx={{ flexGrow: 1 }}>
            © All rights reserved Alan Emir Araujo Pino 
            </Typography>
            
            <BottomNavigation
            showLabels
            /*value={this.props.page}*/
            onChange={(event, newValue) => {
                console.log(newValue)
                //setValue(newValue);
                //this.setState({ page: newValue });
                //handlePage = () => {this.props.onPage(newValue)}
                //page = "https://www.google.com"
                switch(newValue){
                    case 0:
                        window.location.href = "https://github.com/EmirAraujo";
                        break
                    case 1:
                        window.location.href = "https://gitlab.com/EmirAraujo";
                        break
                    case 2:
                        window.location.href = "https://scholar.google.com/citations?user=C3vQsNEAAAAJ&hl=en";
                        break
                    case 3:
                        window.location.href = "https://www.linkedin.com/in/emiraraujo/";
                        break
                    case 4:
                        this.props.sendData(newValue);
                        break
                    default:
                    break
                }
                console.log(this.props.page)
                //ReactDOM.render(Tick, document.getElementById('root'));
            }}
            >
            <BottomNavigationAction label="Github" icon={<GitHubIcon />} />
            <BottomNavigationAction label="Gitlab" icon={<CodeIcon />} />
            <BottomNavigationAction label="Google Schoolar" icon={<FeedIcon />} />
            <BottomNavigationAction label="Linkedin" icon={<LinkedInIcon />} />
            <BottomNavigationAction label="Legal" icon={<PolicyIcon />} />
            </BottomNavigation>


            </Paper>
            </Box>
        );
    }
}

export default FootNote;