import { Component } from "react";
import { isMobile } from "react-device-detect";

import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from "@mui/material";

class Gallery extends Component{
    constructor(props) {
        super(props);
        this.state = { itemData: [
            {img: 'basquet',
            title: 'Analisis de canasta'},
            {img: 'covarianceMatrix',
            title: 'matriz de covarianza'},
            {img: 'deepLearning',
            title: 'Entrenamiento de aprendizaje profundo'},
            {img: 'evaluation',
            title: 'Analisis de modelos'},
            {img: 'graph',
            title: 'Analisis de grafos'},
            {img: 'hardwareTesting',
            title: 'Hardware testing'},
            {img: 'histogram',
            title: 'Histogramas'},
            {img: 'humanoid',
            title: 'Robotica'},
            {img: 'multiClass',
            title: 'Evaluacion multiclase'},
            {img: 'multiLabelBinary',
            title: 'Clasificacion binaria mulietiqueta'},
            {img: 'PCS',
            title: 'Analisis de componentes'},
            {img: 'procesamientoSenales',
            title: 'Procesamiento de señales'},
            {img: 'scatterPlot',
            title: 'Analisis visual'},
            {img: 'scatterPlot2',
            title: 'Graficos de dispersion'},
            {img: 'ROC',
            title: 'curva ROC'},
            /*{img: '',
            title: ''},*/
          ]
            }
            if(isMobile){
              this.state.itemData.map(x=>x["img"]+="_mobile")
            }
    }
    render(){
        return (
          <div>
          <Box sx={{ width: 'auto', height: '300', overflowY: 'scroll' }}>
            <ImageList variant="masonry" cols={isMobile ? 2 : 4} gap={12}>
              {this.state.itemData.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    src={require(`../assets/${item.img}.webp`)}
                    //srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    //loading="lazy"
                  />
                  <ImageListItemBar  actionIcon={
                              <IconButton sx={{ color: "grey" }}>
                                <InfoIcon />
                              </IconButton>
                        } position="below" title={item.title} />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
          </div>
        );
      }

}
export default Gallery