import * as React from 'react';

import { Component } from "react";
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import SendIcon from '@mui/icons-material/Send';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ImageListItem from '@mui/material/ImageListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
class ListServices extends Component {
  constructor(props) {
    super(props);
    this.state={open:false,
      title:props.title,
      l2 : props.listText
    }
  }

  setOpen = (val) => { this.setState( {open:val}); }

  handleClick = () => {
      this.setOpen(!this.state.open);
    };

  render() { 
      //console.log(listText)
      var l3 = this.state.l2.map((v) =>  <ListItemText primary={v} /> ) 
      //console.log(l2)

  return( 
    <div> 
      <ListItemButton onClick={this.handleClick}>
      <ListItemIcon>
      <SendIcon />
      </ListItemIcon>
      <ListItemText primary={this.state.title} />
      {this.state.open ? <ExpandLess /> : <ExpandMore />} 
      </ListItemButton>
  
      <Collapse in={this.state.open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
          {l3}
          <ImageListItem />
      </List>
      </Collapse> 
      </div> );
  }  
}
ListServices.defaultProps = {title:'nada'};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

class Services extends Component  {
    
    constructor(props) {
        super(props);
        this.state={
          l1:['AGV','Laser','Sonar','CAN'],
          l2:['KPI'
          ,'Análisis descriptivo'
          ,'Análisis predictivo'
          ,'Análisis prescriptivo'
          ,'Machine Learning'
          ,'Deep Learning'
          ,'Análisis de grafos' ]
        }
    }

    
    
    render(){
    return (
        <Stack spacing={2} container justifyContent="center">
        
        <Item> Servicios Web
          <p/>
          <a href="https://scholar.google.com/citations?user=C3vQsNEAAAAJ&hl=en">
            DC3 en linea
          </a>
        </Item>


        <Item> Servicios de ingenieria:
        <List
        sx={{  width: '100%', bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        /*subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Nested List Items
          </ListSubheader>
        }//*/
      >
        <Typography align='center' variant="body2" component="div" sx={{ flexGrow: 1 }}>
          <ListServices title='Desarrollo de Software' listText={ this.state.l2}/>
          <ListServices title='Robotica' listText={this.state.l1}/>
          <ListServices title='Analisis de datos' listText={ this.state.l2}/>
        </Typography >

      </List>
      </Item>
      </Stack>

    );
  }

}

export default Services;