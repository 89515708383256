import FileUpload from "./FileUpload";

const Servicios = () => {
    return (
    <div>
      <h1>Whisper</h1>
      <FileUpload />
    </div>
  );
  };
  
  export default Servicios;